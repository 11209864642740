import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import Link from 'next/link';
import * as Sentry from '@sentry/nextjs';
import { ContentSection } from './content-section';
import { PageSection } from './page-section';
import Footer from './footer';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { toast } from 'react-toastify';
import { TOKEN_KEY, useAuthInfo } from '../../lib/jwt';
import { useRouter } from 'next/router';
import { resolveNewAppUrl, resolveMainAppUrl, resolveCreatorPortalUrl } from '../../helpers/link';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import MonetizationOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import { ASSETS_BASE_URL } from '../../lib/constants';

export type PageWithNavProps = {
  fixedNav?: boolean;
  navClass?: string;
  mainClass?: string;
};

export const PageWithNav: React.FC<PageWithNavProps> = props => {
  const [loggedIn, setLoggedin] = useState(false);
  const [currentPage, setCurrentPage] = useState('/');

  const [authInfo, token] = useAuthInfo(false);
  const [userAvatar, setUserAvatar] = useState<string>();
  const [subMenu, setSubMenu] = useState('');
  const [profileSub, setProfileSub] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [mobileActive, setMobileActive] = useState('');
  const [showMobileSubmenuProfile, setShowMobileSubmenuProfile] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setCurrentPage(router.asPath);
    setMobileActive(router.asPath);
    if (token) {
      setLoggedin(true);
    } else {
      setLoggedin(false);
    }
  }, [token]);

  const getUserAvatar = async (userId: number) => {
    if (!userId) return;

    try {
      await fetch(`/api/user/${userId}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          setUserAvatar(data?.result?.avatar);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserAvatar(authInfo?.id);
  }, []);

  const logOut = event => {
    if (loggedIn) {
      event.preventDefault();
      event.stopPropagation();
      Sentry.configureScope(scope => scope.setUser(null));
      const cookies = new Cookies();
      cookies.remove(TOKEN_KEY, {
        path: '/',
        domain: '.voyce.me'
      });
      toast.success('Logged out', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      router.replace('/');
      setLoggedin(false);
    }
  };

  const renderUserContent = (hideOnMobile: boolean = false) => {
    return (
      <div
        className={`${
          hideOnMobile ? 'hidden lg:flex' : 'flex'
        } relative items-center gap-x-4 font-sans-alt text-base lg:mt-1`}
      >
        <Link href={resolveNewAppUrl('/search')}>
          <a>
            <img src={`${ASSETS_BASE_URL}system/icons/search.svg`} alt="search" />
          </a>
        </Link>
        {loggedIn && (
          <Link href={resolveNewAppUrl('/quests')}>
            <a>
              <img src={`${ASSETS_BASE_URL}system/icons/quest.svg`} alt="quest" />
            </a>
          </Link>
        )}
        {loggedIn && (
          <div
            className="relative w-10 h-10 flex-none"
            onMouseLeave={() => {
              setProfileSub(false);
            }}
          >
            <img
              src={`${ASSETS_BASE_URL}${userAvatar}`}
              className="h-10 w-10 rounded-full relative pointer-cursor"
              onClick={() => {
                setShowMobileSubmenuProfile(!showMobileSubmenuProfile);
              }}
              onMouseOver={() => setProfileSub(true)}
            />
            {profileSub && (
              <div className="flex hover:flex flex-col absolute top-10 w-48 pt-8 right-0 text-sm">
                <div className="rounded-lg overflow-hidden border border-blueGray-800 bg-blueGray-800">
                  <ul className="overflow-hidden text-blueGray-50">
                    <li>
                      <Link href={resolveNewAppUrl('/settings/profile')}>
                        <a className="px-5 py-3 block hover:bg-gray-600 hover:text-blueGray-25">
                          My Profile
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href={resolveNewAppUrl('/settings/account')}>
                        <a className="px-5 py-3 block hover:bg-gray-600 hover:text-blueGray-25">
                          Settings
                        </a>
                      </Link>
                    </li>
                    <li>
                      <a
                        onClick={logOut}
                        className="px-5 py-3 block hover:bg-gray-600 hover:text-blueGray-25 cursor-pointer"
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
        {loggedIn ? (
          <Link href={resolveCreatorPortalUrl("/series")}>
            <a className="bg-red-500 px-3 py-2.5 text-gray-25 rounded">Publish</a>
          </Link>
        ) : (
          <Link href={resolveNewAppUrl('/login')}>
            <a className="bg-red-500 px-3 py-2.5 text-gray-25 rounded">Login</a>
          </Link>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full overflow-hidden min-h-screen">
      <header
        className={`border-solid border-b-1 border-gray-500 z-50 relative bg-blueGray-900`}
      >
        <div className="lg:px-2 flex justify-between container mx-auto pt-2 3xl:px-32 font-sans-alt">
          {/* Navigation items */}
          <div
            className={`
              hidden
              h-full bg-transparent 
              lg:flex justify-start items-center font-semibold text-gray-400
            `}
          >
            <ul className="flex items-center gap-x-6 text-base">
              <li className="mr-6">
                <Link href="/">
                  <div className="flex content-center items-center">
                    <div className="imgWrap self-center mx-auto w-full">
                      <img
                        className="py-2"
                        alt="logo"
                        style={{ height: '56.9px', width: '46px' }}
                        src={`${ASSETS_BASE_URL}system/icons/logo.png`}
                      />
                    </div>
                    <h1 className="ml-4 py-[0.7rem] text-gray-25 wr-lg">Voyce.me</h1>
                  </div>
                </Link>
              </li>
              <li
                onMouseOver={() => {
                  setSubMenu('');
                }}
              >
                <Link href={resolveNewAppUrl('/')}>
                  <a
                    className={`${currentPage === '/' &&
                      'text-gray-25 border-b-2'} hover:border-b-2 border-gray-50 border-solid py-4`}
                  >
                    Home
                  </a>
                </Link>
              </li>
              <li
                onMouseOver={() => {
                  setSubMenu('');
                }}
              >
                <Link href={resolveNewAppUrl('/originals')}>
                  <a className="border-gray-50 py-4 hover:border-b-2">Originals</a>
                </Link>
              </li>
              <li
                onMouseOver={() => {
                  setSubMenu('Discover');
                }}
              >
                <Link href={'#'}>
                  <a
                    className={`border-gray-50 ${
                      subMenu === 'Discover' ? 'border-b-2' : ''
                    } py-4`}
                  >
                    Discover
                  </a>
                </Link>
                <div className="inline-block ml-2">
                  {subMenu === 'Discover' ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </div>
              </li>
              <li
                onMouseOver={() => {
                  setSubMenu('Shop');
                }}
              >
                <Link href={resolveNewAppUrl('/shop')}>
                  <a
                    className={`border-gray-50 ${
                      subMenu === 'Shop' ? 'border-b-2' : ''
                    } py-4`}
                  >
                    Shop
                  </a>
                </Link>
                <div className="inline-block ml-2">
                  {subMenu === 'Shop' ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </div>
              </li>
              <li
                onMouseOver={() => {
                  setSubMenu('');
                }}
              >
                <Link href={'/plaza'}>
                  <a
                    className={`${currentPage === '/plaza' &&
                      'text-gray-25 border-b-2'} hover:border-b-2 border-gray-50 border-solid py-4`}
                  >
                    Plaza
                  </a>
                </Link>
              </li>
              {loggedIn && (
                <li>
                  <Link href={resolveNewAppUrl('/library')}>
                    <a className="border-gray-50 hover:border-b-2 py-4">My Library</a>
                  </Link>
                </li>
              )}
              {loggedIn && authInfo.role === 1 && (
                <li>
                  <Link href={resolveMainAppUrl('/admin')}>
                    <a className="border-gray-50 hover:border-b-2 py-4">Admin</a>
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <div>{renderUserContent(true)}</div>
        </div>

        {subMenu && (
          <div
            className="absolute font-sans-alt bg-blueGray-800 -bottom-14 left-0 h-14 w-full flex justify-center items-center gap-12"
            onMouseLeave={() => {
              setSubMenu('');
            }}
          >
            {subMenu === 'Discover' && (
              <>
                <Link href={resolveNewAppUrl('/discover/hot')}>
                  <a className="text-gray-500 flex items-center gap-2">
                    <WhatshotIcon />
                    What's Hot
                  </a>
                </Link>
                <Link href={resolveNewAppUrl('/discover/random')}>
                  <a className="text-gray-500 flex items-center gap-2">
                    <ShuffleIcon />
                    Surprise Me
                  </a>
                </Link>
                <Link href={resolveNewAppUrl('/discover/genre')}>
                  <a className="text-gray-500 flex items-center gap-2">
                    <MenuBookOutlinedIcon />
                    By Genre
                  </a>
                </Link>
                <Link href={resolveNewAppUrl('/discover/type')}>
                  <a className="text-gray-500 flex items-center gap-2">
                    <CategoryOutlinedIcon />
                    By Type
                  </a>
                </Link>
              </>
            )}
            {subMenu === 'Shop' && (
              <>
                <Link href={'https://shop.voyce.me/'}>
                  <a className="text-gray-500 flex items-center gap-2">
                    <CardGiftcardIcon />
                    Merchandise
                  </a>
                </Link>
                <Link href={resolveNewAppUrl('/shop')}>
                  <a className="text-gray-500 flex items-center gap-2">
                    <MonetizationOutlinedIcon />
                    Collectibles
                  </a>
                </Link>
                {loggedIn && (
                  <Link href={resolveNewAppUrl('/shop/history')}>
                    <a className="text-gray-500 flex items-center gap-2">
                      <ListAltIcon />
                      Purchase History
                    </a>
                  </Link>
                )}
              </>
            )}
          </div>
        )}

        <div className="border-solid font-sans-alt lg:hidden border-b-[1px] border-gray-500">
          <div className="container items-center justify-between mx-auto flex px-2 md:px-0">
            <div className="text-left py-4">
              <Link href="/">
                <a className="flex content-center">
                  <img
                    className="py-2"
                    alt="logo"
                    style={{ height: '56.9px', width: '46px' }}
                    src={`${ASSETS_BASE_URL}system/icons/logo.png`}
                  />
                </a>
              </Link>
            </div>
            <div className="text-right">
              {showMobileMenu ? (
                <div className="mt-2">{renderUserContent()}</div>
              ) : (
                <button
                  onClick={() => {
                    setShowMobileMenu(true);
                  }}
                >
                  <span className="text-gray-25 mt-2 py-6 ">
                    <MenuIcon />
                  </span>
                </button>
              )}
            </div>
          </div>
          {/* MENU / SUBMENU */}
          <div
            className={`
            z-50 absolute w-full bg-blueGray-900 border-solid border-gray-500 border-b-[1px] transition-all duration-300 ease-linear overflow-hidden
            ${showMobileMenu ? 'mt-2' : ''}
          `}
            style={{ height: showMobileMenu ? '460px' : '0' }}
          >
            <div className={`relative`}>
              {/* Menu */}
              {showMobileSubmenuProfile ? (
                <div>
                  <div className="container mx-auto">
                    <div
                      className="flex gap-2 items-center text-xs cursor-pointer font-normal text-gray-400"
                      onClick={() => {
                        setShowMobileSubmenuProfile(false);
                      }}
                    >
                      <ChevronLeftIcon />
                      back
                    </div>
                    <div className="p-2">
                      <div className="block">
                        <Link href={resolveNewAppUrl('/settings/profile')}>
                          <a
                            className={`py-2 pr-2 mr-6 font-semibold inline-block text-gray-500`}
                            onClick={() => {
                              setShowMobileMenu(false);
                            }}
                          >
                            My Profile
                          </a>
                        </Link>
                      </div>
                      <div className="block">
                        <Link href={resolveNewAppUrl('/settings/account')}>
                          <a
                            className={`py-2 pr-2 mr-6 font-semibold inline-block text-gray-500`}
                            onClick={() => {
                              setShowMobileMenu(false);
                            }}
                          >
                            Settings
                          </a>
                        </Link>
                      </div>
                      <div className="block">
                        <a
                          onClick={logOut}
                          className={`py-2 pr-2 mr-6 font-semibold inline-block text-gray-500 cursor-pointer`}
                        >
                          Logout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="container mx-auto text-gray-25 font-semibold">
                  <div className="p-2">
                    <div className="block">
                      <Link href={resolveNewAppUrl('/')}>
                        <a className="text-gray-500 flex py-2 pl-4 pr-2">
                          <span className="grow">Home</span>
                        </a>
                      </Link>
                      <Link href={resolveNewAppUrl('/originals')}>
                        <a className="text-gray-500 flex py-2 pl-4 pr-2">
                          <span className="grow">Originals</span>
                        </a>
                      </Link>
                      <div>
                        <div
                          onClick={() => {
                            if (mobileActive === 'Discover')
                              setMobileActive(router.asPath);
                            else setMobileActive('Discover');
                          }}
                          className={`${
                            mobileActive === 'Discover'
                              ? 'border-solid border-l-4 text-gray-25 bg-gray-600 border-red-500'
                              : 'text-gray-500'
                          }  py-2 pl-4 flex justify-between cursor-pointer`}
                        >
                          <span className="grow">Discover</span>
                          <span
                            className={`${
                              mobileActive === 'Discover'
                                ? 'text-gray-25'
                                : 'text-gray-500'
                            } pr-2`}
                          >
                            {mobileActive === 'Discover' ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </span>
                        </div>
                      </div>
                      {mobileActive === 'Discover' && (
                        <div className="pl-8 font-normal border-solid text-gray-25 bg-gray-600">
                          <Link href={resolveNewAppUrl('/discover/hot')}>
                            <a className="flex py-2 items-center gap-2">
                              <WhatshotIcon />
                              What's Hot
                            </a>
                          </Link>
                          <Link href={resolveNewAppUrl('/discover/random')}>
                            <a className="flex py-2 items-center gap-2">
                              <ShuffleIcon />
                              Surprise Me
                            </a>
                          </Link>
                          <Link href={resolveNewAppUrl('/discover/genre')}>
                            <a className="flex py-2 items-center gap-2">
                              <MenuBookOutlinedIcon />
                              By Genre
                            </a>
                          </Link>
                          <Link href={resolveNewAppUrl('/discover/type')}>
                            <a className="flex py-2 items-center gap-2">
                              <CategoryOutlinedIcon />
                              By Type
                            </a>
                          </Link>
                        </div>
                      )}
                      <div>
                        <div
                          onClick={() => {
                            if (mobileActive === 'Shop') setMobileActive(router.asPath);
                            else setMobileActive('Shop');
                          }}
                          className={`${
                            mobileActive === 'Shop'
                              ? 'border-solid border-l-4 text-gray-25 bg-gray-600 border-red-500'
                              : 'text-gray-500'
                          } py-2 pl-4 flex justify-between cursor-pointer`}
                        >
                          <span className="grow">Shop</span>
                          <span
                            className={`${
                              mobileActive === 'Shop' ? 'text-gray-25' : 'text-gray-500'
                            } pr-2`}
                          >
                            {mobileActive === 'Shop' ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </span>
                        </div>
                      </div>
                      {mobileActive === 'Shop' && (
                        <div className="pl-8 font-normal border-solid text-gray-25 bg-gray-600">
                          <Link href={'https://shop.voyce.me/'}>
                            <a className="py-2 flex items-center gap-2">
                              <CardGiftcardIcon />
                              Merchandise
                            </a>
                          </Link>
                          <Link href={resolveNewAppUrl('/shop')}>
                            <a className="py-2 flex items-center gap-2">
                              <MonetizationOutlinedIcon />
                              Collectibles
                            </a>
                          </Link>
                          {loggedIn && (
                            <Link href={resolveNewAppUrl('/shop/history')}>
                              <a className="py-2 flex items-center gap-2">
                                <ListAltIcon />
                                Purchase History
                              </a>
                            </Link>
                          )}
                        </div>
                      )}
                      <Link href={'/plaza'}>
                        <a
                          className={`${
                            mobileActive === '/plaza'
                              ? 'border-solid border-l-4 text-gray-25 bg-gray-600 border-red-500'
                              : 'text-gray-500'
                          } flex py-2 pl-4 pr-2`}
                        >
                          <span className="grow">Plaza</span>
                        </a>
                      </Link>
                      {loggedIn && (
                        <Link href={resolveNewAppUrl('/library')}>
                          <a className="flex py-2 pl-4 pr-2 text-gray-500">
                            <span className="grow">My Library</span>
                          </a>
                        </Link>
                      )}
                    </div>
                    <span
                      className="text-gray-25 absolute top-0 right-2"
                      onClick={() => {
                        setShowMobileMenu(false);
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                </div>
              )}
              <span
                className="text-gray-25 absolute top-0 right-2"
                onClick={() => {
                  setShowMobileMenu(false);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>
        </div>
      </header>

      <main className={'w-full h-full flex-grow ' + props.mainClass}>
        {props.children}
      </main>

      <footer className="w-full bg-purple">
        <PageSection>
          <ContentSection width="xl">
            <Footer />
          </ContentSection>
        </PageSection>
      </footer>
    </div>
  );
};
