export function resolveNewAppUrl(path: string) {
  switch (process.env.NEXT_PUBLIC_STAGE) {
    case 'DEV':
      return `https://develop.voyce.me${path}`;
    case 'STG':
      return `https://staging.voyce.me${path}`;
    default:
      return `https://www.voyce.me${path}`;
  }
}

export function resolveMainAppUrl(path: string) {
  switch (process.env.NEXT_PUBLIC_STAGE) {
    case 'DEV':
      return `https://dev.voyce.me${path}`;
    case 'STG':
      return `https://stg.voyce.me${path}`;
    default:
      return `https://cw.voyce.me${path}`;
  }
}

export function resolveCreatorPortalUrl(path: string) {
  switch (process.env.NEXT_PUBLIC_STAGE) {
    case "DEV":
    case "LOCAL":
      return `https://develop-creators.voyce.me${path}`;
    case "STG":
      return `https://staging-creators.voyce.me${path}`;
    default:
      return `https://creators.voyce.me${path}`;
  }
}

export const isProd = Boolean(process.env.NEXT_PUBLIC_STAGE === 'PROD');
