export type PageSectionProps = {
  className?: string;
  contentClass?: string;
};

export const PageSection: React.FC<PageSectionProps> = (props) => {
  return (
    <div className={`w-full ${props.className ?? ''}`}>
      <div className={`w-full max-w-screen-2xl mx-auto ${props.contentClass ?? ''}`}>
        {props.children}
      </div>
    </div>
  );
};
