import Link from 'next/link';
import link from '../../constants/links'
import { ASSETS_BASE_URL } from '../../lib/constants';

export default function Footer() {
  return (
    <footer className="border-solid border-t-[1px] border-gray-500">
      {/* Main content */}
      <div className="container mx-auto py-4 md:pb-14 md:hidden">
        {/* Follow us */}
        <div className="text-center md:text-left pb-6">
          <p className="font-bold pt-8 pb-2">FOLLOW US</p>
          <div className="mx-auto">
            <a href={link.discord} className="mr-1 inline-block" id='wr_footer_discord'>
              <img src={`${ASSETS_BASE_URL}system/icons/icon-discord.png`} width="25" height="25" />
            </a>
            <a href={link.tiktok} className="mr-1 inline-block" id='wr_footer_tiktok'>
              <img src={`${ASSETS_BASE_URL}system/icons/icon-tiktok.png`} width="25" height="25" />
            </a>
            <a href={link.facebook} className="mr-1 inline-block" id='wr_footer_facebook'>
              <img src={`${ASSETS_BASE_URL}system/icons/icon-fb.png`} width="25" height="25" />
            </a>
            <a href={link.twitter} className="mr-1 inline-block" id='wr_footer_twitter'>
              <img src={`${ASSETS_BASE_URL}system/icons/icon-twitter.png`} width="25" height="25" />
            </a>
            <a href={link.instagram} className="mr-1 inline-block" id='wr_footer_instagram'>
              <img src={`${ASSETS_BASE_URL}system/icons/icon-instagram.png`} width="25" height="25" />
            </a>
          </div>
        </div>
        {/* Other */}
        <div className="text-center md:text-left">
          <Link href={link.terms}><p className="text-sm py-2 text-gray-600 dark:text-gray-400" id="wr_footer_terms">Terms & Conditions</p></Link>
          <Link href={link.privacy}><p className="text-sm py-2 text-gray-600 dark:text-gray-400" id="wr_footer_privacy">Privacy Policy</p></Link>
          <Link href={link.contactUs}><p className="text-sm py-2 text-gray-600 dark:text-gray-400" id="wr_footer_contact_us">Contact Us</p></Link>
        </div>
        {/* @2023 */}
        <p className="text-center md:text-left text-sm py-10 text-gray-600 dark:text-gray-400">©2023 VoyceMe. All rights reserved.</p>
        {/* Logo */}
        <img className="py-2 mx-auto"  src="/logo-white.png" width="46" height="40" />
      </div>
      {/* Footer 2 */}
      <div className="hidden md:block md:border-solid md:border-t-[1px] md:border-gray-500 py-4">
        <div className="container mx-auto md:flex md:flex-row">
          {/* @2023 */}
          <div className="grow w-2/5 text-center md:text-left">
            <p className="text-sm text-gray-600 dark:text-gray-400">©2023 VoyceMe. All rights reserved.</p>
          </div>
          {/* Follow us */}
          <div className="grow w-1/5 text-center">
            <div className="mx-auto">
              <a href={link.discord} className="mr-1 inline-block">
                <img className="" src={`${ASSETS_BASE_URL}system/icons/icon-discord.png`} width="25" height="23" />
              </a>
              <a href={link.tiktok} className="mr-1 inline-block">
                <img className=""  src={`${ASSETS_BASE_URL}system/icons/icon-tiktok.png`} width="25" height="23" />
              </a>
              <a href={link.facebook} className="mr-1 inline-block">
                <img className="" src={`${ASSETS_BASE_URL}system/icons/icon-fb.png`} width="25" height="23" />
              </a>
              <a href={link.twitter} className="mr-1 inline-block">
               <img className="" src={`${ASSETS_BASE_URL}system/icons/icon-twitter.png`} width="25" height="23" />
              </a>
              <a href={link.instagram} className="mr-1 inline-block">
                <img className="" src={`${ASSETS_BASE_URL}system/icons/icon-instagram.png`} width="25" height="23" />
              </a>
            </div>
          </div>
          {/* Other */}
          <div className="grow w-2/5 text-center xl:text-right">
            <Link href={link.terms}><p className="text-sm text-gray-600 dark:text-gray-400 md:ml-2 xl:ml-8 inline">Terms & Conditions</p></Link>
            <Link href={link.privacy}><p className="text-sm text-gray-600 dark:text-gray-400 md:ml-2 xl:ml-8 inline">Privacy Policy</p></Link>
            <Link href={link.contactUs}><p className="text-sm text-gray-600 dark:text-gray-400 md:ml-2 xl:ml-8 inline">Contact Us</p></Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
