const link = {
    appleStore: "https://apps.apple.com/us/app/voyceme/id1600864310",
    googlePlay: "https://play.google.com/store/apps/details?id=com.io.voyceme&pli=1",
    discord: "https://discord.com/invite/PXjZJ824JN",
    tiktok: "https://www.tiktok.com/@voyceme?lang=en",
    facebook: "https://facebook.com/VoyceDotMe",
    twitter: "https://twitter.com/Voyce_Me",
    instagram: "https://www.instagram.com/voyce.me/",
    terms: "/pages/terms-of-use",
    privacy: "/pages/privacy-policy",
    contactUs: "/pages/contact-us",
    faq: "/pages/faqs",
  };

export default link;
  