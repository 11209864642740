export const DAY_IN_MS = 24 * 60 * 60 * 1000;

export const DAYS_FOR_POLLS_POPULARITY_REFRESH = 1;
export const DAYS_FOR_POLLS_OUTDATED_REFRESH = 10;
export const DAYS_FOR_POSTS_POPULARITY_REFRESH = 1;
export const DAYS_FOR_POSTS_OUTDATED_REFRESH = 10;
export const DAYS_FOR_PANELS_POPULARITY_REFRESH = 1;
export const DAYS_FOR_PANELS_OUTDATED_REFRESH = 10;
export const DAYS_FOR_AUTHORS_POPULARITY_REFRESH = 1;
export const DAYS_FOR_AUTHORS_OUTDATED_REFRESH = 1;
export const DAYS_FOR_WEEKLY_POPULARITY_REFRESH = 7;

export const DAYS_FOR_FILTER_ALL = 30;

export const PLAZA_IMAGE_MAX_WIDTH = 800;
export const PLAZA_IMAGE_MAX_HEIGHT = 600;

export const MIN_CONFIDENCE = 70.0;

export const ASSETS_BASE_URL = '//dlkfxmdtxtzpb.cloudfront.net/';
export const TOKEN_ERROR_MESSAGE = 'Token expired';
export const ALL_REACTIONS = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
export const NOVEL_TYPE_ID = 6;
export const WEB_COMICS_TYPE_ID = 4;
export const MANGA_TYPE_ID = 2;
export const TYPE_OPTIONS = {
  All: 0,
  Manga: MANGA_TYPE_ID,
  Webcomics: WEB_COMICS_TYPE_ID,
  Novel: NOVEL_TYPE_ID
};
export const API_GIPHY_KEY = 'Wte2y5V5um6sPYJK0kWlQkmTUcNtC2gx';
