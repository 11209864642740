// TODO: this component needs a way to specify "no padding at small sizes"
// regardless of the main width.
type ContentSectionProps = {
  // TODO: consider adding width 'sm' for narrower content that sits in the
  // middle of the screen, like the login form.
  width: 'xs' | 'sm' | 'md' | 'mdNoPad' | 'lg' | 'xl';
  className?: string;
  split?: 'left' | 'right';
  splitBreakpoint?: 'sm' | 'md' | 'lg' | 'xl';
};

export const ContentSection: React.FC<ContentSectionProps> = props => {
  // NOTE: class names are explicitly enumerated here to work with PurgeCSS.
  const xPaddings = {
    xl: ['px-2', 'md:px-8', 'lg:px-8', 'xl:px-0'],
    lg: ['px-2', 'md:px-8', 'lg:px-8', 'xl:px-22'],
    md: ['px-4', 'md:px-16', 'lg:px-16', 'xl:px-32'],
    mdNoPad: ['px-0', 'md:px-16', 'lg:px-16', 'xl:px-30'],
    sm: ['px-4', 'md:px-16', 'lg:px-16', 'xl:px-72'],
    xs: ['px-4', 'md:px-16', 'lg:px-16', 'xl:px-96']
  };
  const lPaddings = {
    xl: ['pl-2', 'md:pl-8', 'lg:pl-8', 'xl:pl-0'],
    lg: ['pl-2', 'md:pl-8', 'lg:pl-8', 'xl:pl-22'],
    md: ['pl-4', 'md:pl-16', 'lg:pl-16', 'xl:pl-32'],
    sm: ['pl-4', 'md:pl-16', 'lg:pl-16', 'xl:pl-72'],
    xs: ['px-4', 'md:px-16', 'lg:px-16', 'xl:px-96']
  };
  const rPaddings = {
    xl: ['pr-2', 'md:pr-8', 'lg:pr-8', 'xl:pr-0'],
    lg: ['pr-2', 'md:pr-8', 'lg:pr-8', 'xl:pr-22'],
    md: ['pr-4', 'md:pr-16', 'lg:pr-16', 'xl:pr-32'],
    sm: ['pr-4', 'md:pr-16', 'lg:pr-16', 'xl:pr-72'],
    xs: ['px-4', 'md:px-16', 'lg:px-16', 'xl:px-96']
  };

  if (!props.split) {
    return (
      <div className={`${xPaddings[props.width].join(' ')} ${props.className ?? ''}`}>
        {props.children}
      </div>
    );
  }

  // For split content sections, padding should apply to both the left and right
  // on small screen sizes (smaller than props.breakpoint) but only to the side
  // specified in props.split at large sizes (when the content sections will be
  // side-by-side instead of stacked).
  const breakIndex = ['sm', 'md', 'lg', 'xl', undefined].indexOf(props.splitBreakpoint);
  const sidePaddings = props.split === 'left' ? lPaddings : rPaddings;
  const paddings = xPaddings[props.width]
    .slice(0, breakIndex)
    .concat(sidePaddings[props.width].slice(breakIndex, 3));

  return (
    <div className={`${paddings.join(' ')} ${props.className ?? ''}`}>
      {props.children}
    </div>
  );
};
